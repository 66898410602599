import { Pipe, PipeTransform } from '@angular/core';
import { GelaCartItem } from '../../services/cart/model/gela-cart-item.model';
import { Decimal } from 'decimal.js';

@Pipe({
  name: 'getAmountForLongGuestCartItem',
})
export class GetAmountForLongGuestCartItemPipe implements PipeTransform {
  public transform(item: GelaCartItem): number {
    if (!item.oArticle.sPl1LoongGoodArticle?.length) {
      return item.decQuantityOrdered;
    }
    if (item.oArticle.sPl1LongGoodUnit === 'MM') {
      return Decimal.div(item.decQuantityOrdered * item.decPL1Length, 1000).toNumber();
    } else if (item.oArticle.sPl1LongGoodUnit === 'CM') {
      return Decimal.div(item.decQuantityOrdered * item.decPL1Length, 100).toNumber();
    } else if (item.oArticle.sPl1LongGoodUnit === 'MT') {
      return item.decQuantityOrdered * item.decPL1Length;
    }
    return item.decQuantityOrdered;
  }
}
