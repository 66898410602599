<ng-container *ngIf="crumbs$ | async as crumbs">
  <div
    *ngIf="crumbs.length"
    class="w-slider-phone md:w-tab-slider-tablet px-5 md:px-10 tablet:px-0 -mx-5 md:-mx-10 tablet:mx-0 tablet:w-full font-montserrat font-medium text-small flex overflow-x-auto scrollbar-none relative mt-1"
  >
    <a
      *transloco="let t; scope: 'shared'"
      [routerLink]="['/' | localizeUrlPath]"
      class="text-small self-start pb-1 relative group whitespace-nowrap"
      >{{ t("shared.breadcrumb.home") }}
      <span
        class="h-3px w-full bg-gela-black transition-all duration-200 opacity-0 group-hover:opacity-100 absolute left-0 bottom-0"
      ></span>
    </a>
    <ng-container *ngFor="let crumb of crumbs">
      <ng-container *transloco="let t; scope: (translate$ | async) ? crumb.label.split('.')[0] : ''">
        <span class="ifont ifont--chevron-right text-3xl -translate-y-1/4"> </span>
        <a
          [routerLink]="crumb.url"
          class="text-small self-start pb-1 relative group whitespace-nowrap mr-1 last:mr-0"
          [innerHTML]="(translate$ | async) ? (t(crumb.label) | truncate : 40 : '...') : (crumb.label | truncate : 40 : '...')"
        >
          <span
            class="h-3px w-full bg-gela-black transition-all duration-200 opacity-0 group-hover:opacity-100 absolute left-0 bottom-0"
          ></span>
        </a>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
