import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareComponent {
  @Input() title: string | undefined;

  constructor(@Inject(DOCUMENT) public document: any, private transloco: TranslocoService) {}

  getMailtoLink(href: string): string {
    let title = this.title || '';

    title = title.replace('&amp;', ' & ');

    const encodedTitle = encodeURIComponent(title);

    return 'mailto:?subject=' + encodedTitle + '&body=' + this.transloco.translate('share.mailto', {}, 'shared') + '%0D%0A' + href;
  }
}
