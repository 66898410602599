<div class="js-header-big--topbar hidden mobile-nav:block js-close-subnav bg-gela-black" *transloco="let t; scope: 'header'">
  <div class="flex justify-end max-w-page 3xl:max-w-midscreen 4xl:max-w-bigscreen px-5 md:px-10 tablet:px-82px mx-auto">
    <div class="mt-1" *ngFor="let topNav of topNav$ | async">
      <app-cms-anchor
        [classes]="'text-white hover:text-primary mr-12 font-montserrat font-medium inline-flex items-center'"
        [href]="topNav.href"
      >
        <span class="ifont text-3xl mr-1" [ngClass]="topNav.icon"></span>
        {{ topNav.text }}
      </app-cms-anchor>
    </div>

    <div class="relative -mr-1" *ngIf="currentVersionKey$ | async as currentVersionKey">
      <div *ngIf="alternative$ | async as alternativeVersions" class="js-select-wrap relative z-20">
        <button
          (click)="toggleLanguageDropdown()"
          type="button"
          class="js-select js-select--language relative rounded-button pl-6 pr-12 py-2.5 uppercase group"
        >
          <span
            class="js-select-text block truncate text-white pointer-events-none font-montserrat font-medium leading-tight group-hover:text-primary"
          >
            {{ currentVersionKey }}
          </span>
        </button>
        <div
          [class.hidden]="!isLanguageDropdownOpen"
          class="js-select-options bg-white divide-y divide-primary-light py-2.5 absolute left-0 border-r border-l border-b border-primary-light rounded-b-10px uppercase mt-1"
        >
          <div
            *ngFor="let version of alternativeVersions"
            class="px-6 py-3 relative w-full flex items-center hover:bg-primary-bright transition-all duration-200 cursor-pointer"
            (click)="changeVersion(version.key)"
            [ngClass]="version.key === currentVersionKey ? 'opacity-50 pointer-events-none' : ''"
          >
            <div class="flex items-center h-4">
              <div class="">
                <input
                  [id]="version.key"
                  name="language"
                  type="radio"
                  class="js-radio cursor-pointer bg-transparent h-4 w-4 text-primary border border-gela-black ring-offset-transparent ring-transparent focus:ring-0 checked:bg-secondary focus:checked:bg-secondary checked:hover:bg-secondary checked:bg-none"
                  [checked]="version.key === currentVersionKey"
                />
              </div>
              <div class="ml-3 w-full cursor-pointer">
                <label
                  [for]="version.key"
                  class="js-radio-label py-3 text-small font-montserrat font-bold w-full inline-block cursor-pointer flex items-center"
                >
                  <span class="js-radio-label--text">{{ version.title }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center relative" *ngIf="{ isLoggedIn: isLoggedIn$ | async, user: user$ | async } as context">
      <span
        class="w-px h-border-topnav bg-primary-light absolute top-2 left-1 -translate-x-px"
        *ngIf="context.isLoggedIn && context.user"
      ></span>
      <p
        class="font-montserrat font-medium truncate leading-tight max-w-256px w-full pl-12 text-primary-light text-right"
        *ngIf="context.isLoggedIn && context.user"
      >
        {{ (context.user.oContact.sFirstName || "") + " " + (context.user.oContact.sLastName || "") }}
      </p>
    </div>
  </div>
</div>
