import { EnvironmentInterface } from './environment.interface';
import { VERSION } from './version';

export const environment: EnvironmentInterface = {
  name: 'TESTING',
  host: 'https://newtest.gela.ch',
  apiUrl: '/api',
  cmsUrl: '/cms',
  ssrPort: 4300,
  outputPath: 'dist/test/gela',
  version: VERSION,
  production: true,
  debugging: false,
  defaultLocale: 'de',
  availableLang: ['de', 'fr'],
  loadTranslationFromServer: true,
  rootLevel: '1206d77a-e305-4098-9284-57a4b66fca7d',
  camionGeiserShippingConditionIds: [10, 12],
  tourPlanDeliveryIds: [10, 12, 61],
  pickupTypeIds: [48, 49],
  sameDayDeliveryIds: [20, 61],
  dontShowRolesWithId: [3, 10],
  auth: {
    routes: {
      home: '/',
      login: '',
    },
  },
  api: {
    host: 'https://newtest.gela.ch',
    branchKey: '',
    webshopId: 2,
  },
  cms: {
    host: 'https://cms.gela.ch',
  },
  sentry: {
    active: true,
    dsn: 'https://a8e4aadb473447c3a96ad8934917ce3d@o920539.ingest.sentry.io/4505074725027840',
  },
  tracking: {
    gtm: 'GTM-5JSJR9VC',
  },
  images: {
    categoryImage: 1,
    productDetailImages: [1],
    manufacturerLogo: 7,
    labelImages: 104,

    cloudinary: {
      active: true,
      cloudName: 'shop-2',
      fetchImageUrl: 'https://images.gela.ch/daten/pictures',
    },
  },
  sComNormBaseUrl: 'https://newtest.gela.ch/api/comnorm',
  clarity: {
    id: 'nmnng0zdxx',
    enabled: true,
  },
};
