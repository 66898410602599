import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CloudinaryModule } from '@cloudinary/ng';
import { A11yModule, AuthModule, LocalizeUrlPathModule } from '@lobos/library-v2';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { NgCeilPipeModule, NgFloorPipeModule, NgRoundPipeModule, NgTruncatePipeModule, NgWherePipeModule } from 'angular-pipes';
import { MarkdownModule } from 'ngx-markdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { AddToCartComponent } from './add-to-cart/add-to-cart.component';
import { ArticleFavoriteComponent } from './article-favorite/article-favorite.component';
import { ArticleItemComponent } from './article-item/article-item.component';
import { ArticleNoteComponent } from './article-note/article-note.component';
import { ArticleOrderedComponent } from './article-ordered/article-ordered.component';
import { ArticlePriceComponent } from './article-price/article-price.component';
import { ArticleShopInfoComponent } from './article-shop-info/article-shop-info.component';
import { BreadcrumbOutletComponent } from './breadcrumb/breadcrumb-outlet.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { CmsAnchorComponent } from './cms-anchor/cms-anchor.component';
import { CustomToastComponent } from './custom-toast/custom-toast.component';
import { FavoriteItemComponent } from './favorite-item/favorite-item.component';
import { FlyoutComponent } from './flyout/flyout.component';
import { FooterMobileNavbarComponent } from './footer-mobile-navbar/footer-mobile-navbar.component';
import { FooterAccordionComponent } from './footer/footer-accordion/footer-accordion.component';
import { FooterComponent } from './footer/footer.component';
import { ButtonBorderComponent } from './form/button-border/button-border.component';
import { ButtonDropdownComponent } from './form/button-dropdown/button-dropdown.component';
import { ButtonComponent } from './form/button/button.component';
import { CheckboxComponent } from './form/checkbox/checkbox.component';
import { InputComponent } from './form/input/input.component';
import { PasswordComponent } from './form/password/password.component';
import { QuantityComponent } from './form/quantity/quantity.component';
import { SelectMultipleComponent } from './form/select-multiple/select-multiple.component';
import { SelectComponent } from './form/select/select.component';
import { TableSelectSmallComponent } from './form/table-select-small/table-select-small.component';
import { TextareaComponent } from './form/textarea/textarea.component';
import { ToggleSwitchComponent } from './form/toggle-switch/toggle-switch.component';
import { CategoryNavFlyoutComponent } from './header/category-nav-flyout/category-nav-flyout.component';
import { CategoryNavigationComponent } from './header/category-navigation/category-navigation.component';
import { HeaderComponent } from './header/header.component';
import { MainNavigationComponent } from './header/main-navigation/main-navigation.component';
import { CartFlyoutComponent } from './header/service-navigation/cart-flyout/cart-flyout.component';
import { FavoritesFlyoutComponent } from './header/service-navigation/favorites-flyout/favorites-flyout.component';
import { MainSearchComponent } from './header/service-navigation/main-search/main-search.component';
import { SearchArticleItemComponent } from './header/service-navigation/search-article-item/search-article-item.component';
import { ServiceNavigationComponent } from './header/service-navigation/service-navigation.component';
import { UserFlyoutComponent } from './header/service-navigation/user-flyout/user-flyout.component';
import { TopbarNavigationComponent } from './header/topbar-navigation/topbar-navigation.component';
import { ImageComponent } from './image/image.component';
import { LinkIconComponent } from './link-icon/link-icon.component';
import { LinkTextComponent } from './link-text/link-text.component';
import { LinkUnderlineComponent } from './link-underline/link-underline.component';
import { LinkComponent } from './link/link.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { MobileNavbarComponent } from './mobile-navbar/mobile-navbar.component';
import { MobileNavigationMenuComponent } from './mobile-navbar/new-mobile-navigation-menu/mobile-navigation-menu.component';
import { MobileProfileMenuComponent } from './mobile-navbar/mobile-profile-menu/mobile-profile-menu.component';
import { ModalCartComponent } from './modal-cart/modal-cart.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { ProductItemComponent } from './product-item/product-item.component';
import { QtyModalComponent } from './qty-modal/qty-modal.component';
import { ResetPasswordModalComponent } from './reset-password-modal/reset-password-modal.component';
import { SalesRepBannerComponent } from './sales-rep-banner/sales-rep-banner.component';
import { ScannerResultsComponent } from './scanner/scanner-results/scanner-results.component';
import { ScannerComponent } from './scanner/scanner/scanner.component';
import { ShareComponent } from './share/share.component';
import { ShellComponent } from './shell/shell.component';
import { TableHeaderSortComponent } from './table-header-sort/table-header-sort.component';
import { TableScrollComponent } from './table-scroll/table-scroll.component';
import { TagComponent } from './tag/tag.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { ArticleMiniCardComponent } from './article-mini-card/article-mini-card.component';
import { ModalSimpleMessageComponent } from './modal-simple-message/modal-simple-message.component';
import { SearchProductItemComponent } from './header/service-navigation/search-product-item/search-product-item.component';
import { GelaButtonComponent } from './gela-button/gela-button.component';
import { ModalDownloadsComponent } from './modal-downloads/modal-downloads.component';
import { ArticleAvailabilityComponent } from './article-availability/article-availability.component';
import { ArticleAvailabilityIconComponent } from './article-availability/article-availability-icon/article-availability-icon.component';
import { ArticleAvailabilityTextComponent } from './article-availability/article-availability-text/article-availability-text.component';
import { ArticleAvailabilityTextAddonComponent } from './article-availability/article-availability-text-addon/article-availability-text-addon.component';
import { InfoIconComponent } from './article-price/info-icon/info-icon.component';
import { PreferredCommissionComponent } from './preferred-commission/preferred-commission.component';
import { ModalOutlineAgreementComponent } from './modal-outline-agreement/modal-outline-agreement.component';
import { FavoriteQtyModalComponent } from './favorite-qty-modal/favorite-qty-modal.component';
import { ScrollTopButtonComponent } from './scroll-top-button/scroll-top-button.component';
import { InViewportComponent } from './in-viewport/in-viewport.component';

const components: any[] = [
  ShellComponent,
  HeaderComponent,
  BreadcrumbComponent,
  BreadcrumbOutletComponent,
  TopbarNavigationComponent,
  ServiceNavigationComponent,
  MainNavigationComponent,
  LoginModalComponent,
  InputComponent,
  ArticleItemComponent,
  CheckboxComponent,
  ProductItemComponent,
  CartFlyoutComponent,
  UserFlyoutComponent,
  FavoritesFlyoutComponent,
  ArticlePriceComponent,
  FavoriteItemComponent,
  CategoryNavigationComponent,
  MainSearchComponent,
  SearchArticleItemComponent,
  CategoryNavFlyoutComponent,
  ButtonDropdownComponent,
  AddToCartComponent,
  LinkUnderlineComponent,
  LinkIconComponent,
  QtyModalComponent,
  ModalConfirmComponent,
  ImageComponent,
  ButtonComponent,
  ButtonBorderComponent,
  SelectComponent,
  QuantityComponent,
  LinkComponent,
  ToggleSwitchComponent,
  ArticleFavoriteComponent,
  TooltipComponent,
  ModalCartComponent,
  TextareaComponent,
  FooterMobileNavbarComponent,
  MobileNavbarComponent,
  MobileNavigationMenuComponent,
  MobileProfileMenuComponent,
  ScannerComponent,
  ScannerResultsComponent,
  PasswordComponent,
  TableHeaderSortComponent,
  TagComponent,
  CmsAnchorComponent,
  FooterComponent,
  FooterAccordionComponent,
  CustomToastComponent,
  CategoryListComponent,
  TableSelectSmallComponent,
  ArticleNoteComponent,
  LinkTextComponent,
  FlyoutComponent,
  SelectMultipleComponent,
  SalesRepBannerComponent,
  ShareComponent,
  ArticleShopInfoComponent,
  TableScrollComponent,
  ResetPasswordModalComponent,
  ArticleMiniCardComponent,
  ModalSimpleMessageComponent,
  SearchProductItemComponent,
  ModalDownloadsComponent,
  ArticleAvailabilityComponent,
  ArticleAvailabilityIconComponent,
  ArticleAvailabilityTextComponent,
  ArticleAvailabilityTextAddonComponent,
  PreferredCommissionComponent,
  ModalOutlineAgreementComponent,
  InViewportComponent,
];

@NgModule({
  declarations: [
    components,
    ArticleOrderedComponent,
    PaginatorComponent,
    GelaButtonComponent,
    InfoIconComponent,
    FavoriteQtyModalComponent,
    ScrollTopButtonComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    ReactiveFormsModule,
    PipesModule,
    TranslocoLocaleModule,
    NgFloorPipeModule,
    FormsModule,
    MarkdownModule,
    LocalizeUrlPathModule,
    CloudinaryModule,
    NgxPaginationModule,
    NgRoundPipeModule,
    A11yModule,
    DirectivesModule,
    NgWherePipeModule,
    NgxSkeletonLoaderModule,
    NgCeilPipeModule,
    NgxPopperjsModule,
    AuthModule,
    NgTruncatePipeModule,
  ],
  exports: [components, ArticleOrderedComponent, PaginatorComponent, GelaButtonComponent, PreferredCommissionComponent],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: '',
    },
  ],
})
export class ComponentsModule {}
