import { Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { ID } from '@datorama/akita';
import { CartHeader } from '@lobos/common';
import { AuthService, CartHeaderQuery, CartItemQuery, CartService, UrlHelperService } from '@lobos/library-v2';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SimpleModalService } from 'ngx-simple-modal';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { ModalCartComponent } from '../../../modal-cart/modal-cart.component';
import { ModalConfirmComponent } from '../../../modal-confirm/modal-confirm.component';
import { LoginModalComponent } from '../../../login-modal/login-modal.component';
import { Router } from '@angular/router';
import { GelaCartItem } from '../../../../services/cart/model/gela-cart-item.model';
import { GelaCartHeader } from '../../../../services/cart/model/gela-cart-header.model';
import { CreateGelaCartItemInterface } from '../../../../services/cart/model/create-gela-cart-item.interface';

@UntilDestroy()
@Component({
  selector: 'app-cart-flyout',
  templateUrl: './cart-flyout.component.html',
  styleUrls: ['./cart-flyout.component.scss'],
})
export class CartFlyoutComponent {
  @Input()
  public mobile: boolean = false;

  @Output()
  public closed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public showFlyout: boolean = false;

  public carts$: Observable<{ key: string; value: string }[]> = this.cartHeaderQuery.selectAll().pipe(
    map((carts: GelaCartHeader[]) =>
      carts.map((cart: GelaCartHeader) => ({
        key: cart.lngOrderID.toString(),
        value: cart.sCartName,
      })),
    ),
  );
  public isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;

  public isLoading$: Observable<boolean> = combineLatest([this.cartItemQuery.selectLoading(), this.cartHeaderQuery.selectLoading()]).pipe(
    map(([l1, l2]: [boolean, boolean]) => l1 || l2),
  );

  public activeCart$: Observable<CartHeader | undefined> = this.cartHeaderQuery.selectActive();
  public activeCartItems$: Observable<GelaCartItem[]> = this.cartHeaderQuery.selectActiveId().pipe(
    filter((id: ID | null | undefined) => !!id),
    switchMap((id: ID | null | undefined) => this.cartItemQuery.selectCartItems(id!)),
  );

  constructor(
    private cartHeaderQuery: CartHeaderQuery<GelaCartHeader>,
    private cartService: CartService<GelaCartHeader, GelaCartItem, CreateGelaCartItemInterface>,
    private cartItemQuery: CartItemQuery<GelaCartItem>,
    private authService: AuthService,
    private modal: SimpleModalService,
    private router: Router,
    private urlHelper: UrlHelperService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}

  open(): void {
    this.showFlyout = true;
    this.closed.emit(false);
  }

  close(): void {
    this.showFlyout = false;
    this.closed.emit(true);
  }

  addCart(): void {
    this.modal.addModal(ModalCartComponent, { addMode: true });
  }

  editCart(id: ID): void {
    this.modal.addModal(ModalCartComponent, { addMode: false, id });
  }

  changeCart(id: string | number): void {
    this.cartService.changeActiveCart(id).pipe(first(), untilDestroyed(this)).subscribe();
  }

  openLoginModal(): void {
    this.modal
      .addModal(LoginModalComponent)
      .pipe(
        first(),
        filter((result: string | null) => result === 'success'),
        debounceTime(500),
        switchMap(() => this.cartHeaderQuery.selectActiveId()),
        tap(() => this.close()),
        switchMap((cartId: ID | null | undefined) => this.router.navigate([this.urlHelper.localizeUrl('checkout/cart'), cartId])),
        first(),
      )
      .subscribe();
  }

  removeItem(item: GelaCartItem) {
    this.modal
      .addModal(ModalConfirmComponent, {
        message: 'header.carts.modal-confirm.message',
        translate: true,
      })
      .pipe(
        filter((result: boolean) => result),
        switchMap(() => this.cartService.deleteCartItem(item)),
        first(),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
