<ng-container *transloco="let t; scope: 'shared'">
  <ng-container *ngIf="oPrice$ | async as oPrice">
    <ng-container *ngIf="article">
      <div [ngClass]="sizeWrapperClasses[size]" class="flex">
        <p [ngClass]="sizePriceClasses[size]">
          <ng-container *ngIf="isLoggedIn$ | async; else guestPrice">
            <ng-container *ngIf="isCartSinglePrice && _cartItem && oPrice.oPriceScaleInfos.length; else normalPrice">
              <ng-container *ngIf="oPrice.decPL1FinalPrice; else noFinalPrice"
                >{{
                  (oPrice.decAmountNetFC < oPrice.decPL1FinalPrice ? oPrice.decAmountNetFC : oPrice.decPL1FinalPrice) | translocoCurrency
                }}
              </ng-container>
              <ng-template #noFinalPrice>
                {{ oPrice.oPriceScaleInfos | getPriceFromPriceScale : _cartItem.decQuantityOrdered | number : "1.2-2" }}
              </ng-template>
            </ng-container>
            <ng-template #normalPrice>
              {{ (oPrice.decAmountNetFC < oPrice.decPriceNet ? oPrice.decAmountNetFC : oPrice.decPriceNet) | number : "1.2-2" }}
            </ng-template>
          </ng-container>
          <ng-template #guestPrice>
            {{ oPrice.decPriceNet | number : "1.2-2" }}
          </ng-template>
        </p>
        <app-flyout
          *ngIf="oPrice.oPriceScaleInfos && oPrice.oPriceScaleInfos.length > 0"
          [closable]="false"
          [title]="t('shared.price.price-scale.title')"
          class="leading-none"
        >
          <button class="js-show-flyout h-full leading-none relative" trigger>
            <app-info-icon class="h-3.5 w-3.5 block ml-2" [ngClass]="priceScaleType === 'articlepage' ? '-mt-6' : ''"></app-info-icon>
          </button>
          <ng-container flyout>
            <div class="flex flex-wrap">
              <table class="js-flyout-table w-full">
                <thead>
                  <tr>
                    <th class="text-small text-right pb-1 pr-10 md:pr-14 last:pr-0">{{ t("shared.price.price-scale.amount") }}</th>
                    <th class="text-small text-right pb-1 pr-10 md:pr-14 last:pr-0">{{ t("shared.price.price-scale.price") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of oPrice.oPriceScaleInfos">
                    <td class="text-small text-right pb-1 pr-10 md:pr-14 last:pr-0">{{ item.decQuantityFrom }}</td>
                    <td class="text-small text-right pb-1 pr-10 md:pr-14 last:pr-0">{{ item.decPriceNet | translocoCurrency }}</td>
                  </tr>
                </tbody>
              </table>
              <ng-container *ngIf="oPrice.decPL1FinalPrice">
                <div class="w-full">
                  <div class="border-t border-solid border-1 pt-2 border-primary-light"></div>
                  <span class="text-small float-right text-right pt-10 pb-1"
                    >{{ t("shared.price.final-price") }} :
                    {{
                      (oPrice.decAmountNetFC < oPrice.decPL1FinalPrice ? oPrice.decAmountNetFC : oPrice.decPL1FinalPrice)
                        | translocoCurrency
                    }}</span
                  >
                </div>
              </ng-container>
              <ng-container *ngIf="oPrice.decPL1RecommendeSalesPrice">
                <div class="w-full">
                  <div class="border-t border-solid border-1 pt-2 border-primary-light"></div>
                  <span class="text-small float-right text-right pt-10 pb-1"
                    >{{ t("shared.price.recommended-sale-price") }} : {{ oPrice.decPL1RecommendeSalesPrice | translocoCurrency }}</span
                  >
                </div>
              </ng-container>
              <ng-container *ngIf="oPrice.oOutlineAgreementItem">
                <div class="w-full">
                  <div class="border-t border-solid border-1 pt-2 border-primary-light"></div>
                  <span class="text-small float-right text-right pt-10 pb-1"
                    >{{ t("shared.price.outline-agreement") }}&nbsp;({{ oPrice.oOutlineAgreementItem.lngContractID }})
                    {{
                      oPrice.oOutlineAgreementItem.decPrice
                        ? (oPrice.oOutlineAgreementItem.decPrice | translocoCurrency)
                        : ("" | translocoCurrency)
                    }}</span
                  >
                </div>
              </ng-container>
            </div>
          </ng-container>
        </app-flyout>
        <!-- app-article-availability here... -->
        <div class="-mt-2 ml-2">
          <ng-content></ng-content>
        </div>
      </div>
      <!--      <p *ngIf="size === 'm'" class="text-small font-montserrat font-bold mb-1 -mt-0.5">statt 275.40</p>-->
      <p *ngIf="size === 'm'" class="text-small font-montserrat font-bold mb-4 -mt-0.5">
        {{
          t("shared.price.package-info", {
            unit: article.lngSalesPriceUnit,
            unitName: article.sQuantityUnitSales
          })
        }}
      </p>
    </ng-container>
  </ng-container>
</ng-container>
