import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() public label: string = '';

  @Input() public inputID: string = '';

  @Input() public type: string = 'text';

  @Input() public name: string = '';

  @Input() public placeholder: string = '';

  @Input() public form: UntypedFormGroup | FormGroup | undefined;

  @Input() public required: boolean = false;

  @Input() public hasError: boolean = false;

  @Input() public errorText: string = '';

  @Input() public min?: number | string = undefined;

  @Input() public maxlength: number | undefined;

  @Input() public grey: boolean = false;

  @Input() public disableField: boolean = false;

  @Input() public value: string = '';

  @Input()
  public valueChangeDebouncing: number = 500;

  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public enterClicked: EventEmitter<undefined> = new EventEmitter<undefined>();

  public valueChangeDebouncer: Subject<string> = new Subject<string>();

  public hasFocus: boolean = false;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.valueChangeDebouncer
      .pipe(debounceTime(this.valueChangeDebouncing), untilDestroyed(this))
      .subscribe((input: string) => this.valueChange.emit(input));
  }

  startAnimation() {
    this.animateLabel(this.el.nativeElement);
  }

  resetAnimation($event: FocusEvent) {
    this.hasFocus = false;
    const target: HTMLInputElement = $event.target as HTMLInputElement;
    if (!target || target.value !== '') return;
    this.el.nativeElement.querySelector('.js-label').classList.remove('scale-81.25', 'top-0');
    this.el.nativeElement.querySelector('.js-label').classList.add('top-1/2');
  }

  animateLabel(inputEl: Element) {
    this.hasFocus = true;
    const label = inputEl.querySelector('.js-label');

    if (!label) return;

    label.classList.add('scale-81.25', 'top-0');
    label.classList.remove('top-1/2');
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.enterClicked.emit(undefined);
    }
  }
}
