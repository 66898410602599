<ng-container *transloco="let t; scope: 'shared'">
  <app-flyout [disableOnTouch]="false" [closable]="false">
    <ng-container trigger>
      <ng-content></ng-content>
    </ng-container>
    <div flyout>
      <ul class="flex justify-between">
        <li>
          <a
            target="_blank"
            [href]="'https://www.linkedin.com/sharing/share-offsite/?url=' + document.location.href"
            class="js-link inline-block hover:text-primary-dark transition-all duration-200"
          >
            <span class="ifont ifont--linkedin text-3xl pointer-events-none"></span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            [href]="'https://www.facebook.com/share.php?u=' + document.location.href"
            class="js-link inline-block hover:text-primary-dark transition-all duration-200"
          >
            <span class="ifont ifont--facebook text-3xl pointer-events-none"></span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            [href]="'https://www.twitter.com/share?url=' + document.location.href"
            class="js-link inline-block hover:text-primary-dark transition-all duration-200"
          >
            <span class="ifont ifont--twitter text-3xl pointer-events-none"></span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            [href]="'https://api.whatsapp.com/send/?text=' + document.location.href"
            class="js-link inline-block hover:text-primary-dark transition-all duration-200"
          >
            <span class="ifont ifont--whatsapp text-3xl pointer-events-none"></span>
          </a>
        </li>
        <li>
          <a
            [href]="getMailtoLink(document.location.href)"
            class="js-link inline-block hover:text-primary-dark transition-all duration-200"
          >
            <span class="ifont ifont--mail text-3xl pointer-events-none"></span>
          </a>
        </li>
      </ul>
    </div>
  </app-flyout>
</ng-container>
